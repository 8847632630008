<template>
  <v-container class="bg-surface-variant">
    <v-row>
      <v-col>
        <v-text-field
          v-model="remoteUserName"
          :rules="[rules.required]"
          label="Remote Username"
        />

        <v-card title="Call To..." variant="tonal">
          <v-container>
            <v-btn type="button" class="mt-2" @click="callFn">Call</v-btn>
            <v-btn type="button" class="mx-2 mt-2 bg-red" @click="endCallFn"
              >End Call</v-btn
            >
            <v-btn type="button" class="mt-2" @click="callAnswerFn"
              >Call Answer</v-btn
            >
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <hr />

    <v-row>
      <v-col>
        <v-card title="Send Message" variant="tonal">
          <v-container>
            <v-text-field
              v-model="message"
              :rules="[rules.required]"
              label="Message"
            />

            <v-btn type="submit" class="mt-2" @click="sendMsgFn"
              >Send Message</v-btn
            >
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toRaw } from "vue";
import JsSIP from "jssip";

var ua = null;
var u = new URLSearchParams(location.search)

var outgoingSession = null;
var incomingSession = null;
var currentSession = null;
var localStream = null;
var constraints = {
      audio: true,
      video: false,     
      mandatory: {
        maxWidth: 640,
        maxHeight: 360
      }
    };
   

export default {
  data() {
    return {

      user: {
        login: u.has('user') ? u.get('user') : '777',
        password: 'password'
      },
      remoteUserName: "bob",
      message: "Hello from alice",
      rules: {
        required: (value) => !!value || "Required!",
      },
      ua: null,
    };
  },

  created() {
    this.$store.dispatch("vsip/subscribe", {
      type: "callEvt",
      listener: (v) => {
        console.log(">evt", v);
      },
    });
  },
  methods: {
    callFn() {
      console.log(">ua", ua);

      // Register callbacks to desired call events
      var eventHandlers = {
        progress: function (e) {
          console.log("call is in progress");
        },
        failed: function (e) {
          console.log("call failed with cause: " + JSON.stringify(e));
        },
        ended: function (e) {
          console.log("call ended with cause: " + e);
        },
        confirmed: function (e) {
          console.log("call confirmed");
        },
        connecting: function () {
          console.log("UA session connecting");
        }
      };

      var options = {
        eventHandlers: eventHandlers,
        mediaConstraints: { 
          audio: true, 
          video: false 
        },
        rtcOfferConstraints: {
            offerToReceiveAudio: 1, 
            offerToReceiveVideo: 1
        },
        pcConfig: {
            hackStripTcp: true, // Важно для хрома, чтоб он не тупил при звонке
            rtcpMuxPolicy: 'negotiate', // Важно для хрома, чтоб работал multiplexing. Эту штуку обязательно нужно включить на астере.
            iceServers: []
        },
      };

      var session = ua.call(`sip:${this.remoteUserName}@example.com`, options);
      session.on('connecting', () => {
        console.log("UA session connecting");
      })
      session.on('progress', () => {
        console.log("UA session progress");
      })
      session.on('failed', () => {
        console.log("UA session failed");
      })
      session.on('ended', () => {
        console.log("UA session ended");
      })
      console.log("session", session)
    },
    /* callFn() {
      this.$store.dispatch("vsip/setMediaDevices");

      this.$store.dispatch("vsip/doCall", {
        target: `${this.remoteUserName}@example.com`,
      });
    }, */

    endCallFn() {
      //this.$store.dispatch("vsip/callTerminate", { callId: 1 });
      //ua.terminateSession()
    },
    /* sendMsgFn() {
      this.$store.dispatch("vsip/sendIM", this.message);
    }, */
    sendMsgFn() {
      // Register callbacks to desired message events
      var eventHandlers = {
        succeeded: function (e) {
          console.log(">MESSAGE: Sent");
        },
        failed: function (e) {
          console.log(">MESSAGE: Failed");
        },
      };

      const options = {
        eventHandlers: eventHandlers,
      };

      ua.sendMessage(
        `sip:${this.remoteUserName}@example.com`,
        this.message,
        options
      );
    },
    callAnswerFn() {
      const id = this.$store.getters["vsip/getSelectedOutputDevice"];
      console.log(">id", id);
      const ol = this.$store.getters["vsip/getInputDeviceList"];
      console.log(">id", ol, ol[2]);

      // const list = this.$store.getters["vsip/getListeners"];
      // const evt = [...list.callEvt];
      // console.log(">evt", evt);

      // evt.forEach((v) => console.log(v));

      this.$store.dispatch("vsip/callAnswer", {
        callId:
          "9d605b9f9773dcb1c2a1e032226d91c30c45b299834e218c54bbb526daeca3ed",
      });
    },

    gotLocalMedia(stream) {
      console.info('Received local media stream');
      localStream = stream;
      //videoView.src = URL.createObjectURL(stream);
    },


    captureLocalMedia() {
      console.info('Requesting local video & audio');
      if (navigator.webkitGetUserMedia)
        navigator.webkitGetUserMedia(constraints, this.gotLocalMedia, function(e){
          console.log('getUserMedia() error: ' + e.name);
        });
    }

  },
  created() {

    if (!localStream)
      this.captureLocalMedia()
    

    const socket = new JsSIP.WebSocketInterface("wss://iptv.it-light.ru/ws");
    const configuration = {
      sockets: [socket],
      uri: `sip:${this.user.login}@example.com`,
      password: this.user.password,
      register: true
    };

    ua = new JsSIP.UA(configuration);
    //this.$store.commit("updateUa", ua);
    ua.start();

    ua.on('newRTCSession', function(data) {
        console.info('onNewRTCSession: ', data);

        if (data.originator == 'remote'){ //incoming call
          console.info("incomingSession, answer the call");
          incomingSession = data.session;
          data.session.answer({
            'mediaConstraints' : constraints,
            'mediaStream': localStream
          });
        } else {
          console.info("outgoingSession");
          outgoingSession = data.session;
          outgoingSession.on('connecting', function (data) {
            console.info('onConnecting - ', data.request);
            currentSession = outgoingSession;
            outgoingSession = null;
          });
        }

        data.session.on('accepted', function(data){
          console.info('onAccepted - ', data);
          if(data.originator == 'remote' && currentSession == null){
            currentSession = incomingSession;
            incomingSession = null;
            console.info("setCurrentSession - ", currentSession);
          }
        });

        data.session.on('confirmed', function(data){
          console.info('onConfirmed - ', data);
          if(data.originator == 'remote' && currentSession == null){
            //currentSession = incomingSession;
            //incomingSession = null;
            console.info("setCurrentSession - ", currentSession);
          }          
        });

        data.session.on('sdp', function(data){
          console.info('onSDP, type - ', data.type, ' sdp - ', data.sdp);
          //data.sdp = data.sdp.replace('UDP/TLS/RTP/SAVPF', 'RTP/SAVPF');
          //console.info('onSDP, changed sdp - ', data.sdp);
        });

        data.session.on('progress', function(data) {
          console.info('onProgress - ', data.originator);
          if(data.originator == 'remote'){
            console.info('onProgress, response - ', data.response);
          }
        });

        data.session.on('peerconnection', function(data) {
          console.info('onPeerconnection - ', data.peerconnection);
          data.peerconnection.onaddstream = function(ev){
              console.info('onaddstream from remote - ', ev);
              //videoView.src = URL.createObjectURL(ev.stream);
          };
        });
      });


    //router.push({ path: "/home" });
  }
};
</script>
